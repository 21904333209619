var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "text--black", attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "mb-4 text-left",
              staticStyle: { color: "white" },
              attrs: { cols: "12", md: "4" }
            },
            [
              _c(
                "div",
                { staticClass: "text-left py-1" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "back-button",
                      attrs: { variant: "light" },
                      on: {
                        click: function($event) {
                          return _vm.$router.go(-1)
                        }
                      }
                    },
                    [_c("b-icon", { attrs: { icon: "arrow-left" } })],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-col",
            { staticClass: "mb-4", attrs: { cols: "12", md: "4" } },
            [
              _vm.user.active == 1
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "danger", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.disable()
                        }
                      }
                    },
                    [
                      _c("b-icon", { attrs: { icon: "x-circle" } }),
                      _vm._v(" Disable ")
                    ],
                    1
                  )
                : _c(
                    "b-button",
                    {
                      attrs: { variant: "info", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.enable()
                        }
                      }
                    },
                    [
                      _c("b-icon", { attrs: { icon: "arrow-clockwise" } }),
                      _vm._v(" Re-activate ")
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-4", attrs: { cols: "12", md: "4" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "success", block: "" },
                  on: {
                    click: function($event) {
                      return _vm.resetPassword()
                    }
                  }
                },
                [
                  _c("b-icon", { attrs: { icon: "key-fill" } }),
                  _vm._v(" Reset Password ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "UserDetails",
        { attrs: { user: _vm.user } },
        [
          [
            _c("EditUser", {
              attrs: { orgId: +_vm.orgId, userId: +_vm.userId, edit: "" },
              on: {
                refresh: function($event) {
                  return _vm.init()
                }
              }
            })
          ]
        ],
        2
      ),
      _c(
        "b-card",
        {
          staticClass: "mb-4",
          staticStyle: { "text-align": "left" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c("div", { staticClass: "d-inline h4" }, [
                    _vm._v("Companies")
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "userrole-item" },
            [
              _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                _vm._v(" Company ")
              ]),
              _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                _vm._v(" Role ")
              ]),
              _c("b-col", { attrs: { cols: "12" } }, [_c("hr")])
            ],
            1
          ),
          _vm._l(_vm.companies, function(comp) {
            return _c(
              "b-row",
              { key: comp.id, staticClass: "userrole-item" },
              [
                _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                  _vm._v(" " + _vm._s(comp.name) + " ")
                ]),
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "3" } },
                  [
                    _c(
                      "b-form-select",
                      {
                        model: {
                          value: comp.role,
                          callback: function($$v) {
                            _vm.$set(comp, "role", $$v)
                          },
                          expression: "comp.role"
                        }
                      },
                      [
                        _c("b-select-option", { attrs: { value: null } }, [
                          _vm._v("None")
                        ]),
                        _c("b-select-option", { attrs: { value: 30 } }, [
                          _vm._v("User")
                        ]),
                        _c("b-select-option", { attrs: { value: 10 } }, [
                          _vm._v("Admin")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "success" },
                      on: {
                        click: function($event) {
                          return _vm.saveRoles()
                        }
                      }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }