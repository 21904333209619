var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline" },
    [
      _c(
        "div",
        {
          staticClass: "ma-0 pa-0 d-inline",
          on: {
            click: function($event) {
              _vm.showModal = true
            }
          }
        },
        [
          _vm._t("default", function() {
            return [
              _vm.edit
                ? _c(
                    "b-button",
                    { attrs: { variant: "success", block: "" } },
                    [
                      _c("b-icon", { attrs: { icon: "pencil" } }),
                      _vm._v(" Edit User ")
                    ],
                    1
                  )
                : _c(
                    "b-button",
                    { attrs: { variant: "success", block: "" } },
                    [
                      _c("b-icon", { attrs: { icon: "plus" } }),
                      _vm._v(" Add User ")
                    ],
                    1
                  )
            ]
          })
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "addorguser",
            title: "Add User",
            "ok-variant": "primary",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          },
          on: {
            hidden: function($event) {
              return _vm.reset()
            },
            shown: function($event) {
              return _vm.init()
            },
            ok: _vm.createUser
          },
          scopedSlots: _vm._u([
            {
              key: "modal-ok",
              fn: function() {
                return [
                  _c("b-icon", { attrs: { icon: "check" } }),
                  _vm._v(" Save ")
                ]
              },
              proxy: true
            },
            {
              key: "modal-cancel",
              fn: function() {
                return [
                  _c("b-icon", { attrs: { icon: "x" } }),
                  _vm._v(" Cancel ")
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "b-form-group",
            { attrs: { label: "First Name", "label-align": "left" } },
            [
              _c("b-input", {
                model: {
                  value: _vm.firstname,
                  callback: function($$v) {
                    _vm.firstname = $$v
                  },
                  expression: "firstname"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: "Last Name", "label-align": "left" } },
            [
              _c("b-input", {
                model: {
                  value: _vm.lastname,
                  callback: function($$v) {
                    _vm.lastname = $$v
                  },
                  expression: "lastname"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: "Email", "label-align": "left" } },
            [
              _c("b-input", {
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }